import { LOCATION_CHANGE } from 'react-router-redux';
import { takeOnce } from '@dbh/redux-saga-extra';
import { select, call, fork, put, all, takeLatest } from 'redux-saga/effects';
import { DAEMON } from '@dbh/redux-extra';
import '@dbh/ssr-data-redux';
import { isServerSideRendering } from '@dbh/environment';
import { CAN_FIRE_PAGE_VIEW_TRACKING_EVENT } from '@dbh/tracking-scripts-redux';
import { NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT } from '@dbh/webpages-events-and-constants-redux';
import { handleLocationChangedToWebpagesRouteWithSearchContext } from '@dbh/webpages-redux';
import 'common-tags';
import '@dbh/invariant-errors-and-warnings';
import { makeSelectLocationHash } from '@dbh/routing-redux';
import { isDaybreakhotelsDomId, scrollIntoView } from '@dbh/dom';
import { requestQueueClearRequested } from '@dbh/request-queue-redux';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import { API_IDS } from '@dbh/api-constants';

/*
 * Listens to page view events, and when it finds a DBH anchor in the hash,
 * and the corresponding DOM element exists, it scrolls to it. It’s also triggered
 * when a link with a DBH anchor is clicked, and it points to the current page.
 * In the latter case, this saga wouldn’t be necessary, but it seems not to cause
 * issues; for simplicity, we ignore the issue.
 */function*handleScrollToAnchorIfNeeded(){const a=yield select(makeSelectLocationHash());if(a&&isDaybreakhotelsDomId(a)){const b=document.querySelector(a);!b||(yield call(scrollIntoView,b));}}

/**
 * Every `60` seconds clears the request queue.
 * @yields {void} .
 */function*handleRequestQueueClearRequestedInterval(){for(throwInServerSideRendering("handleRequestQueueClearRequestedInterval");;){const a=yield new Promise(a=>setTimeout(()=>{a(put(requestQueueClearRequested()));},6e4));yield a;}}function*forkHandleRequestQueueClearRequestedInterval(){yield fork(handleRequestQueueClearRequestedInterval);}

const REDUX_AND_SAGA_KEY="app";({[API_IDS.shoppingcarts]:!0,[API_IDS.bookings]:!0,[API_IDS.hotels]:!1});

function*rootSaga(){yield all([takeLatest(NAVIGATED_TO_WEBPAGES_ROUTE_WITH_SEARCH_CONTEXT,handleLocationChangedToWebpagesRouteWithSearchContext),...[],...(isServerSideRendering()?[]:[takeLatest(CAN_FIRE_PAGE_VIEW_TRACKING_EVENT,handleScrollToAnchorIfNeeded)]),...(isServerSideRendering()?[]:// We don't want to possibly prolong the "idle time" or other
// "page loaded" metrics measured by Google web vitals, on the landing
// page, so we start this loop only after the user has first navigated.
[takeOnce(LOCATION_CHANGE,forkHandleRequestQueueClearRequestedInterval)])]);}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON};

export { rootSaga as default, injectedSagaConfiguration, rootSaga };
